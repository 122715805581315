import SntIcon from "./SntIcon";

const SntDiagnosticFailureIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5276 10.875C11.0815 10.875 11.4287 10.2521 11.1539 9.75155L6.62628 1.50335C6.34937 0.998884 5.65063 0.998883 5.37372 1.50335L0.846105 9.75155C0.57132 10.2521 0.918541 10.875 1.47239 10.875H10.5276ZM5.33858 4.11432C5.30633 3.71149 5.6117 3.36601 6 3.36601C6.3883 3.36601 6.69367 3.71149 6.66142 4.11432L6.39061 7.49725C6.37368 7.70873 6.20386 7.8714 6 7.8714C5.79614 7.8714 5.62632 7.70873 5.60939 7.49725L5.33858 4.11432ZM6 8.6223C5.60162 8.6223 5.27868 8.95849 5.27868 9.3732C5.27868 9.78791 5.60162 10.1241 6 10.1241C6.39838 10.1241 6.72132 9.78791 6.72132 9.3732C6.72132 8.95849 6.39838 8.6223 6 8.6223Z"
        fill="#E00000"
      />
    </SntIcon>
  );
};

export default SntDiagnosticFailureIcon;
