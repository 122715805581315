import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SntRadio from "../../SntRadio/SntRadio";
import useOuterClick from "../useOutsideElement";
import GeneralFooterFilter from "../GeneralFooterFilter";
import ExcludeLabel from "../ExcludeLabel";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import moment from "./../../../constants/SensolusMoment";
import StringUtils from "../../../utils/StringUtils";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { AdvanceFilter, FilterBody, FilterHeaderStyle } from "../FilterStyle";
import IcomoonIcon from "../../Icons/IcomoonIcon";
import SntCloseSmallIcon from "../../Icons/SntCloseSmallIcon";
import SntArrowDownIcon from "../../Icons/SntArrowDownIcon";
import usePositionFilter from "../usePositionFilter";

const DateFilter = ({
  descriptor = {
    key: "lastActivity",
    label: "Last activity",
    description: "The last detected activity",
    filterType: "DATE",
  },
  data = {
    filterKey: "appliedProfileLastUpdate",
    filterType: "DATE",
    categoryId: "activityInfo",
    searchType: "STATIC",
    filterValue: {
      dateFilterType: "BETWEEN_RELATIVE",
      lastValue: 2,
      lastUnit: "HOURS",
      intervalSign: "PAST",
      absoluteStartDate: "2020-11-09T00:00:00+0700",
      absoluteEndDate: "2020-11-10T23:59:59+0700",
      relativeStartDate: "-1M1w",
      relativeEndDate: "+1M1w",
      month: 1,
    },
    notFilter: false,
  },
  onChange = (e) => {
    console.log(e);
  },
  disabled = false,
}) => {
  const language = useSelector((state) => state.language);
  let [isShow, setShow] = useState(false);
  let [title, setTitle] = useState("");
  const [errors, setErrors] = useState(null);

  let [selectedValue, setSelectedValue] = useState("");

  let [notFilter, setNotFilter] = useState(false);

  let popupStyleRef = useRef({});
  const { popupRef, buttonRef, getPosition } = usePositionFilter();

  const optionsUnitDate = [
    { value: "MONTHS", label: language.months_key },
    { value: "WEEKS", label: language.weeks_key },
    { value: "DAYS", label: language.maintenance_unit_DAYS_key },
    { value: "HOURS", label: language.hours_full_key },
    { value: "MINUTES", label: language.minutes_key },
  ];

  const optionsMonths = [
    { value: "1", label: language.january_key },
    { value: "2", label: language.february_key },
    { value: "3", label: language.march_key },
    { value: "4", label: language.april_key },
    { value: "5", label: language.may_key },
    { value: "6", label: language.june_key },
    { value: "7", label: language.july_key },
    { value: "8", label: language.august_key },
    { value: "9", label: language.september_key },
    { value: "10", label: language.october_key },
    { value: "11", label: language.november_key },
    { value: "12", label: language.december_key },
  ];

  const [initialValues, setInitialValues] = useState({
    greater: "1",
    greaterUnit: "MONTHS",
    greaterIntervalSign: "PAST",
    smaller: "1",
    smallerUnit: "MONTHS",
    smallerIntervalSign: "PAST",
    absoluteStartDate: new Date().toLocaleDateString("en-CA"),
    absoluteEndDate: new Date().toLocaleDateString("en-CA"),
    relativeStartDate: "-1M1w",
    relativeEndDate: "+1h2m",
    month: new Date().getMonth() + 1,
  });

  const patt = /^[-|+](([0-9]+M)?([0-9]+w)?([0-9]+d)?([0-9]+h)?([0-9]+m)?)$/g;

  const validationSchema = Yup.object({
    greater: Yup.number()
      .required(StringUtils.replaceStr(language.greater_than_key, 0))
      .moreThan(0),
    smaller: Yup.number()
      .required(StringUtils.replaceStr(language.greater_than_key, 0))
      .moreThan(0),
    absoluteStartDate: Yup.date().required(
      StringUtils.replaceStr(language.valid_start_end_days_key, 0)
    ),
    absoluteEndDate: Yup.date().required(
      StringUtils.replaceStr(language.valid_start_end_days_key, 0)
    ),
    relativeStartDate: Yup.string()
      .required(StringUtils.replaceStr(language.regex_error_key, 0))
      .matches(patt, StringUtils.replaceStr(language.regex_error_key, 0)),
    relativeEndDate: Yup.string()
      .required(StringUtils.replaceStr(language.regex_error_key, 0))
      .matches(patt, StringUtils.replaceStr(language.regex_error_key, 0)),
  });

  const optionIntervalSign = [
    { value: "PAST", label: language.ago_key },
    { value: "FUTURE", label: language.from_now_key },
  ];

  // run when input data was changed
  useEffect(() => {
    let { filterValue } = data;
    let _title = language.all_key;
    let operator = "";
    if (filterValue) {
      operator = filterValue.dateFilterType;

      let unitDate = optionsUnitDate.find(
        (m) => m.value === filterValue.lastUnit
      );

      if (operator === "MORE_THAN") {
        _title =
          language.more_than_key +
          " " +
          filterValue.lastValue +
          " " +
          unitDate.label;
        setInitialValues((previous) => {
          return {
            ...previous,
            greater: filterValue.lastValue,
            greaterUnit: filterValue.lastUnit,
            greaterIntervalSign: filterValue.intervalSign,
          };
        });
      } else if (operator === "LESS_THAN") {
        _title =
          language.less_than_key +
          " " +
          filterValue.lastValue +
          " " +
          unitDate.label;
        setInitialValues((previous) => {
          return {
            ...previous,
            smaller: filterValue.lastValue,
            smallerUnit: filterValue.lastUnit,
            smallerIntervalSign: filterValue.intervalSign,
          };
        });
      } else if (operator === "BETWEEN_ABSOLUTE") {
        _title =
          new Date(filterValue.absoluteStartDate).toDateString() +
          "-" +
          new Date(filterValue.absoluteEndDate).toDateString();
        setInitialValues((previous) => {
          return {
            ...previous,
            absoluteStartDate: moment(filterValue.absoluteStartDate).format(
              "yyyy-MM-DD"
            ),
            absoluteEndDate: moment(filterValue.absoluteEndDate).format(
              "yyyy-MM-DD"
            ),
          };
        });
      } else if (operator === "BETWEEN_RELATIVE") {
        _title = `${filterValue.relativeStartDate} ${language.till_key} ${filterValue.relativeEndDate}`;
        setInitialValues((previous) => {
          return {
            ...previous,
            relativeStartDate: filterValue.relativeStartDate,
            relativeEndDate: filterValue.relativeEndDate,
          };
        });
      } else if (operator === "MONTH") {
        let month = new Date(filterValue.absoluteStartDate);
        let monthOption = optionsMonths.find(
          (m) => m.value === filterValue.month
        );
        _title = optionsMonths[month.getMonth()].label;
        let _month = month.getMonth() + 1;
        if (monthOption) {
          _title = monthOption.label;
          _month = monthOption.value;
        }
        setInitialValues((previous) => {
          return {
            ...previous,
            month: _month,
          };
        });
      } else if (operator === "NULL") {
        //do nothing
        _title = language.has_no_value_key;
        setInitialValues((previous) => {
          return {
            ...previous,
          };
        });
      }
    }
    setSelectedValue(operator);
    setNotFilter(data.notFilter);
    setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data.filterValue, language.all_key, language.till_key]);

  const getData = useCallback(() => {
    let newData = JSON.parse(JSON.stringify(data));

    let filterValue = {
      dateFilterType: null, // options are: MORE_THAN|LESS_THAN|BETWEEN_ABSOLUTE|BETWEEN_RELATIVE
      lastValue: null, // visible when "dateFilterType" == "LAST" -- field value
      lastUnit: null, // visible when "dateFilterType" == "LAST" -- field unit: "MONTHS","WEEKS","DAYS","HOURS","MINUTES"
      intervalSign: null, // options are: FUTURE|PAST
      absoluteStartDate: null, // "ISO formatted start date",    // visible when "dateFilterType" == "BETWEEN_ABSOLUTE" -- start date field
      absoluteEndDate: null, // "ISO formatted end date",        // visible when "dateFilterType" == "BETWEEN_ABSOLUTE" -- end date field
      relativeStartDate: null, // visible when "dateFilterType" == "BETWEEN_RELATIVE" -- start expression field
      relativeEndDate: null, // visible when "dateFilterType" == "BETWEEN_RELATIVE" -- end expression field
    };

    filterValue.dateFilterType = selectedValue;

    if (selectedValue === "MORE_THAN") {
      filterValue.lastValue = Number.parseInt(initialValues.greater);
      filterValue.lastUnit = initialValues.greaterUnit;
      filterValue.intervalSign = initialValues.greaterIntervalSign;
      newData.filterValue = filterValue;
    } else if (selectedValue === "LESS_THAN") {
      filterValue.lastValue = Number.parseInt(initialValues.smaller);
      filterValue.lastUnit = initialValues.smallerUnit;
      filterValue.intervalSign = initialValues.smallerIntervalSign;
      newData.filterValue = filterValue;
    } else if (selectedValue === "BETWEEN_ABSOLUTE") {
      filterValue.absoluteStartDate = moment(
        initialValues.absoluteStartDate
      ).format();
      filterValue.absoluteEndDate = moment(initialValues.absoluteEndDate)
        .endOf("day")
        .format();
      newData.filterValue = filterValue;
    } else if (selectedValue === "BETWEEN_RELATIVE") {
      filterValue.relativeStartDate = initialValues.relativeStartDate;
      filterValue.relativeEndDate = initialValues.relativeEndDate;
      newData.filterValue = filterValue;
    } else if (selectedValue === "MONTH") {
      filterValue.month = initialValues.month;
      newData.filterValue = filterValue;
    } else if (selectedValue === "NULL") {
      newData.filterValue = filterValue;
    }

    newData.notFilter = notFilter;

    return newData;
  }, [data, selectedValue, notFilter, initialValues]);

  const refWrapper = useOuterClick((e) => {
    if (isShow && Object.keys(errors).length === 0) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    }
  });

  const isChanged = useCallback(() => {
    let oldDataFilter = data.filterValue || {};
    let newData = getData();
    let newDataFilter = newData.filterValue || {};

    if (
      oldDataFilter.absoluteEndDate === newDataFilter.absoluteEndDate &&
      oldDataFilter.absoluteStartDate === newDataFilter.absoluteStartDate &&
      oldDataFilter.dateFilterType === newDataFilter.dateFilterType &&
      oldDataFilter.intervalSign === newDataFilter.intervalSign &&
      oldDataFilter.lastUnit === newDataFilter.lastUnit &&
      oldDataFilter.lastValue === newDataFilter.lastValue &&
      oldDataFilter.relativeEndDate === newDataFilter.relativeEndDate &&
      oldDataFilter.relativeStartDate === newDataFilter.relativeStartDate &&
      (data.notFilter === newData.notFilter ||
        (data.notFilter === undefined && newData.notFilter === false) ||
        (data.notFilter === false && newData.notFilter === undefined)) &&
      oldDataFilter.month === newDataFilter.month
    )
      return false;
    return true;
  }, [data, getData]);

  const onClickButton = (e) => {
    if (isShow) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    } else {
      popupStyleRef.current = getPosition();
      setShow(true);
    }
  };

  const onAppliedFilter = () => {
    setShow(false);
    if (isChanged()) onChange && onChange(getData());
  };

  const onClearFilter = () => {
    setSelectedValue("");
    setNotFilter(false);
    setShow(false);
    onChange &&
      onChange({
        idx: descriptor._idx,
        data: null,
      });
  };

  const excludeLabel = useMemo(() => {
    setNotFilter(data.notFilter && data.notFilter);

    return data.notFilter && <ExcludeLabel />;
  }, [data.notFilter]);

  return (
    <AdvanceFilter ref={refWrapper}>
      <Button
        ref={buttonRef}
        variant="sensolus-greylight"
        title={descriptor.description}
        disabled={disabled}
        onClick={onClickButton}
      >
        {selectedValue && (
          <SntCloseSmallIcon
            className="me-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClearFilter();
            }}
          />
        )}
        <span>{descriptor.label || descriptor.description}</span>:{" "}
        <span>{excludeLabel}</span>
        <FilterHeaderStyle>{title}</FilterHeaderStyle>
        <SntArrowDownIcon />
      </Button>
      <FilterBody
        ref={popupRef}
        style={{ display: isShow ? "block" : "none", ...popupStyleRef.current }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(value) => {
            onAppliedFilter();
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => {
            setTimeout(() => setErrors(errors), 0);
            return (
              <form onSubmit={handleSubmit}>
                <Container>
                  <div className="mb-3 mt-3">
                    <SntRadio
                      value="MORE_THAN"
                      selectedValue={selectedValue}
                      onChange={setSelectedValue}
                      label={language.more_than_key}
                    />
                  </div>
                  <Row className="mb-3">
                    <Col className="d-flex">
                      <Form.Control
                        name="greater"
                        value={values.greater}
                        type="number"
                        min="0"
                        className={` me-2 ${
                          errors.greater && selectedValue === "MORE_THAN"
                            ? "is-invalid"
                            : "is-valid"
                        }`}
                        disabled={selectedValue !== "MORE_THAN"}
                        style={{ width: "3.5rem" }}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            greater: e.target.value,
                          });
                        }}
                      />
                      <Form.Select
                        name="greaterUnit"
                        value={values.greaterUnit}
                        className={` me-2`}
                        disabled={selectedValue !== "MORE_THAN"}
                        style={{ width: "8.5rem" }}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            greaterUnit: e.target.value,
                          });
                        }}
                      >
                        {optionsUnitDate.map((greaterUnit) => (
                          <option
                            key={greaterUnit.value}
                            value={greaterUnit.value}
                          >
                            {greaterUnit.label}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Select
                        name="greaterIntervalSign"
                        value={values.greaterIntervalSign}
                        disabled={selectedValue !== "MORE_THAN"}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            greaterIntervalSign: e.target.value,
                          });
                        }}
                      >
                        {optionIntervalSign.map((optionIntervalSign) => (
                          <option
                            key={optionIntervalSign.value}
                            value={optionIntervalSign.value}
                          >
                            {optionIntervalSign.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: errors.greater ? "block" : "none" }}
                    >
                      {errors.greater}
                    </Form.Control.Feedback>
                  </Row>

                  <div className="mb-3">
                    <SntRadio
                      value="LESS_THAN"
                      selectedValue={selectedValue}
                      onChange={setSelectedValue}
                      label={language.less_than_key}
                    />
                  </div>
                  <Row className="mb-3">
                    <Col className="d-flex">
                      <Form.Control
                        name="smaller"
                        value={values.smaller}
                        type="number"
                        min="0"
                        className={`float-start  me-2 ${
                          errors.smaller && selectedValue === "LESS_THAN"
                            ? "is-invalid"
                            : "is-valid"
                        }`}
                        disabled={selectedValue !== "LESS_THAN"}
                        style={{ width: "3.5rem" }}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            smaller: e.target.value,
                          });
                        }}
                      />
                      <Form.Select
                        name="smallerUnit"
                        value={values.smallerUnit}
                        className={`float-start  me-2`}
                        disabled={selectedValue !== "LESS_THAN"}
                        style={{ width: "8.5rem" }}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            smallerUnit: e.target.value,
                          });
                        }}
                      >
                        {optionsUnitDate.map((smallerUnit) => (
                          <option
                            key={smallerUnit.value}
                            value={smallerUnit.value}
                          >
                            {smallerUnit.label}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Select
                        name="smallerIntervalSign"
                        value={values.smallerIntervalSign}
                        className={`float-start`}
                        disabled={selectedValue !== "LESS_THAN"}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            smallerIntervalSign: e.target.value,
                          });
                        }}
                      >
                        {optionIntervalSign.map((optionIntervalSign) => (
                          <option
                            key={optionIntervalSign.value}
                            value={optionIntervalSign.value}
                          >
                            {optionIntervalSign.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: errors.smaller ? "block" : "none" }}
                    >
                      {errors.smaller}
                    </Form.Control.Feedback>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <SntRadio
                        value="BETWEEN_ABSOLUTE"
                        selectedValue={selectedValue}
                        onChange={setSelectedValue}
                        label={language.between_dates_key}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-between">
                      <Form.Control
                        name="absoluteStartDate"
                        value={values.absoluteStartDate}
                        type="date"
                        className={` me-2 ${
                          (errors.absoluteStartDate ||
                            errors.absoluteEndDate) &&
                          selectedValue === "BETWEEN_ABSOLUTE"
                        }`}
                        style={{ position: "relative" }}
                        disabled={selectedValue !== "BETWEEN_ABSOLUTE"}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            absoluteStartDate: e.target.value,
                          });
                        }}
                      />
                      <div className=" me-2 align-self-center">
                        {language.to_key}
                      </div>
                      <Form.Control
                        name="absoluteEndDate"
                        value={values.absoluteEndDate}
                        type="date"
                        className={`${
                          (errors.absoluteStartDate ||
                            errors.absoluteEndDate) &&
                          selectedValue === "BETWEEN_ABSOLUTE"
                        }`}
                        style={{ position: "relative" }}
                        disabled={selectedValue !== "BETWEEN_ABSOLUTE"}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            absoluteEndDate: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{
                        display:
                          errors.absoluteStartDate || errors.absoluteEndDate
                            ? "block"
                            : "none",
                      }}
                    >
                      {errors.absoluteStartDate || errors.absoluteEndDate}
                    </Form.Control.Feedback>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <SntRadio
                        value="BETWEEN_RELATIVE"
                        selectedValue={selectedValue}
                        onChange={setSelectedValue}
                        label={language.in_timerange_key}
                      />
                      <IcomoonIcon
                        className="float-end"
                        icon="question"
                        title={language.date_filter_title_key}
                        size={16}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-between">
                      <div className=" me-2 align-self-center">
                        {" "}
                        {language.from_key}{" "}
                      </div>
                      <Form.Control
                        name="relativeStartDate"
                        value={values.relativeStartDate}
                        placeholder="-1M1w"
                        type="text"
                        className={` me-2 ${
                          (errors.relativeStartDate ||
                            errors.relativeEndDate) &&
                          selectedValue === "BETWEEN_RELATIVE"
                        }`}
                        style={{ position: "relative" }}
                        disabled={selectedValue !== "BETWEEN_RELATIVE"}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            relativeStartDate: e.target.value,
                          });
                        }}
                      />
                      <div className=" me-2 align-self-center">
                        {language.till_key}
                      </div>
                      <Form.Control
                        name="relativeEndDate"
                        placeholder="+1h2m"
                        value={values.relativeEndDate}
                        type="text"
                        className={`${
                          (errors.relativeStartDate ||
                            errors.relativeEndDate) &&
                          selectedValue === "BETWEEN_RELATIVE"
                        }`}
                        style={{ position: "relative" }}
                        disabled={selectedValue !== "BETWEEN_RELATIVE"}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            relativeEndDate: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{
                        display:
                          errors.relativeStartDate || errors.relativeEndDate
                            ? "block"
                            : "none",
                      }}
                    >
                      {errors.relativeStartDate || errors.relativeEndDate}
                    </Form.Control.Feedback>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <SntRadio
                        value="MONTH"
                        selectedValue={selectedValue}
                        onChange={setSelectedValue}
                        label={language.v41_month_key}
                      />
                      <IcomoonIcon
                        className="float-end"
                        icon="question"
                        size={16}
                        title={language.date_filter_title_key}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Select
                        name="month"
                        value={values.month}
                        className={`float-start`}
                        disabled={selectedValue !== "MONTH"}
                        onChange={(e) => {
                          handleChange(e);
                          setInitialValues({
                            ...initialValues,
                            month: e.target.value,
                          });
                        }}
                        style={{ width: "9rem" }}
                      >
                        {optionsMonths.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <SntRadio
                        value="NULL"
                        selectedValue={selectedValue}
                        onChange={setSelectedValue}
                        label={language.has_no_value_key}
                      />
                    </Col>
                  </Row>
                </Container>

                <GeneralFooterFilter
                  onClearFilter={(e) => onClearFilter(e)}
                  onAppliedFilter={(e) => {
                    if (Object.keys(errors).length === 0) {
                      handleSubmit();
                    }
                  }}
                  isNotFilter={notFilter}
                  onCheckNotFilter={(e) => {
                    setNotFilter(e);
                  }}
                  disableCheckBox={!selectedValue}
                />
              </form>
            );
          }}
        </Formik>
      </FilterBody>
    </AdvanceFilter>
  );
};

export default DateFilter;
