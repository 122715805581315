import { useField } from "formik";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const Styles = styled.div`
  input.form-control-color-custom {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: transparent;
  }
  input.form-control-color-custom::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(255, 17, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

const InputColor = ({ name, placeholder = "", disabled = false, onChange }) => {
  const [field, meta] = useField(name);

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  const customClss = field.value ? "" : "form-control-color-custom";

  return (
    <Styles>
      <Form.Control
        type="color"
        className={`${formGroupClass} ${customClss}`}
        title={placeholder}
        disabled={disabled}
        {...field}
        value={field.value || "#FFFFFF"}
        onChange={(e) => {
          field.onChange(e);
          onChange && onChange(e.target);
        }}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Styles>
  );
};

export default InputColor;
