import { ICON_TYPE, svgIcon } from "./SvgIcon";

const InfraMarkerIcon = (beacon) => {
  return svgIcon({
    iconUrl: beacon.trackerIcon
      ? beacon.trackerIcon
      : "/images/beacon/icons/default_icon.svg",
    id: beacon.id,
    state: beacon.name || beacon.serial,
    iconAnchor: infraIconAnchor(),
    network: false,
    type: ICON_TYPE.infraType,
  });
};

export default InfraMarkerIcon;

const infraIconAnchor = () => {
  return [15, 31];
};

const infraIconTooltipOffset = () => {
  return [0, -26];
};

export { infraIconAnchor, infraIconTooltipOffset };
