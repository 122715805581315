// 3rd parties
import { useSelector } from "react-redux";

// owner
import { userSelector } from "../../../redux/selectors";
import moment from "../../../constants/SensolusMoment";
import DateRangeWidget from "../../widgets/dates/DateRangeWidget";

/**
 * A DateRangeWidget component for selecting date ranges.
 *
 * @param {object} value - An object containing date range information.
 * @param {string} value.viewMode - The view mode for the date range ('day', 'week', 'month', 'custom').
 * @param {moment} value.start - The start of the date range.
 * @param {moment} value.end - The end of the date range.
 *
 * @param {object} overrideDisplays - Override default display modes. (default: {day: true, week: true, month: true, year: true, custom: true, all: false, last90d: false})
 * @param {boolean} overrideDisplays.day - Display day mode.
 * @param {boolean} overrideDisplays.week - Display week mode.
 * @param {boolean} overrideDisplays.month - Display month mode.
 * @param {boolean} overrideDisplays.year - Display year mode.
 * @param {boolean} overrideDisplays.custom - Display custom mode.
 * @param {boolean} overrideDisplays.last90d - Display last90d mode.
 * @param {boolean} overrideDisplays.all - Display all mode.
 *
 * @param {object} custom - An object for custom mode options.
 * @param {object} custom.hideRanges - Buttons to hide in custom mode.
 * @param {boolean} custom.hideRanges.today - Hide 'Today' button.
 * @param {boolean} custom.hideRanges.yesterday - Hide 'Yesterday' button.
 * @param {boolean} custom.hideRanges.last7Day - Hide 'Last 7 Days' button.
 * @param {boolean} custom.hideRanges.last30Day - Hide 'Last 30 Days' button.
 * @param {boolean} custom.hideRanges.last90Day - Hide 'Last 90 Days' button.
 * @param {boolean} custom.hideRanges.thisMonth - Hide 'This Month' button.
 * @param {boolean} custom.hideRanges.lastMonth - Hide 'Last Month' button.
 *
 * @param {object} all - An object for all mode options.
 * @param {moment} all.start - The start date of all mode. (default: last 10 years)
 *
 * @param {moment} options.minDate - The minimum date for the date range.
 * @param {moment} options.maxDate - The maximum date for the date range. (default: now())
 *
 * @param {boolean} disabled - Disable action buttons.
 *
 * @returns {JSX.Element} The rendered DateRangeContainer component.
 *
 * @example
 * // Usage with custom mode and hidden buttons:
 * <DateRangeContainer
 *   value={{
 *     viewMode: 'custom',
 *     start: moment('2023-10-01'),
 *     end: moment('2023-10-15'),
 *   }}
 *   overrideDisplays={{ custom: true, year: false, all: false }}
 *   custom={{ hideRanges: { last7Day: true, last30Day: true } }}
 *   options={{ maxDate: moment('2023-12-31') }}
 * />
 */
const DateRangeContainer = ({
  value,
  onChange,
  overrideDisplays,
  custom,
  all,
  minDate,
  maxDate = moment(),
  disabled = false,
  enableTime,
}) => {
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector(userSelector);
  const { weekStart } = loginInfo;

  return (
    <DateRangeWidget
      value={value}
      onChange={onChange}
      overrideDisplays={overrideDisplays}
      custom={custom}
      all={all}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      language={language}
      weekStart={weekStart}
      enableTime={enableTime}
    />
  );
};

export default DateRangeContainer;
