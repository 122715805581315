import { useEffect, useMemo, useState } from "react";
import { Badge, CloseButton, OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import SntLink from "./SntLink";
import SntDiagnosticWarningIcon from "@icons/SntDiagnosticWarningIcon.js";
import SntDiagnosticFailureIcon from "@icons/SntDiagnosticFailureIcon.js";

const BadgeStyle = styled(Badge)`
  font-size: ${(props) => (props.size === "sm" ? "0.625rem" : "0.75rem")};
  font-weight: normal;
  border-radius: 2px;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  white-space: nowrap;
  vertical-align: middle;
`;

const DefaultStyle = styled(Badge)`
  font-size: ${(props) => (props.size === "sm" ? "0.625rem" : "0.75rem")};
  font-weight: normal;
  border-radius: 2px;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  white-space: nowrap;
  vertical-align: middle;

  background: #e7e7e1 !important;
  color: #424b59 !important;
  border: none !important;
`;

export const TagMore = ({
  render,
  tags = [],
  visibleNumber = 4,
  moreClass = "snt-tags",
  title = "All Tags",
  onClickMoreLink,
}) => {
  const language = useSelector((state) => state.language);

  const limitedTags = useMemo(() => {
    return tags
      .filter((t, i) => i < visibleNumber)
      .map((tag, index) => {
        return render ? (
          render(tag, index, getDiagnosticLabelByMetricKey)
        ) : (
          <DefaultTag className="me-2 mb-2" key={index} tagName={tag.name} />
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, visibleNumber]);
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    setShowPopover(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const expandedTags = useMemo(() => {
    if (tags.length <= visibleNumber) return null;
    let _expandedTags = tags
      .filter((t, i) => i >= visibleNumber)
      .map((tag, index) => {
        return render ? (
          render(tag, index, getDiagnosticLabelByMetricKey)
        ) : (
          <DefaultTag className="me-2 mb-2" key={index} tagName={tag.name} />
        );
      });

    return (
      <>
        {_expandedTags.length > 0 && (
          <OverlayTrigger
            trigger="click"
            placement="top"
            show={showPopover}
            rootClose={true}
            overlay={
              <Popover arrowProps="shape">
                <Popover.Header as="h3">
                  {title}
                  <CloseButton
                    // size="xs"
                    className="float-end"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPopover(false);
                    }}
                  />
                </Popover.Header>
                <Popover.Body
                  className="overflow-auto"
                  style={{ maxHeight: 250 }}
                >
                  {_expandedTags}
                </Popover.Body>
              </Popover>
            }
          >
            <SntLink
              id={"tag" + Date.now()}
              onClick={(e) => {
                if (onClickMoreLink) {
                  onClickMoreLink();
                } else {
                  setShowPopover(!showPopover);
                }
              }}
              className={moreClass}
            >
              +{tags.length - visibleNumber} {language.more_key}
            </SntLink>
          </OverlayTrigger>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, visibleNumber, showPopover]);

  function getDiagnosticLabelByMetricKey(metricKey) {
    let keyToLabel = {
      messageCompleteness: language.message_transmission_success,
      hwFailure: language.hw_failure_key,
      networkQuality: language.network_quality_key,
      liveness: language.liveness_key,
      gpsFixResult: language.gps_fix_success_key,
      sensorCompletenessRatio: language.sensor_data_completeness_key,
      journeyCompletenessRatio: language.journey_completeness_ratio_key,
      wifiResult: language.wifi_resolution_success_key,
      shortStopRatio: language.short_stop_key,
      totalWithoutThrottlingEvent: language.days_without_throttling_ratio_key,
      hoursWithoutOfflineRatio: language.time_online_key,
      averageBatteryConsumptionInADay:
        language.average_battery_consumption_in_a_day_key,
      averageNumberOfSendMessageInADay:
        language.average_number_of_send_message_day_key,
    };
    return keyToLabel[metricKey] != null ? keyToLabel[metricKey] : metricKey;
  }
  return (
    <>
      {limitedTags}
      {expandedTags}
    </>
  );
};

export default TagMore;

export const AssetTagMore = (props) => {
  return (
    <TagMore
      {...props}
      render={(tag, index) => (
        <AssetTag key={index} tagName={tag.name} className="me-2 mb-2" />
      )}
    />
  );
};

export const DefaultTag = (props) => {
  const {
    tagName,
    title,
    children,
    size = "md",
    className = "",
    onClick,
    style,
  } = { ...props };

  return (
    <BadgeStyle
      size={size}
      bg="white"
      className={`border border-sensolus-grey ${className}`}
      style={{ color: "var(--bs-body-color)", ...style }}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const WarningIconTag = (props) => {
  const {
    tagName,
    title,
    children,
    size = "md",
    className = "",
    onClick,
    style,
  } = { ...props };

  return (
    <BadgeStyle
      size={size}
      bg="white"
      className={`border border-sensolus-grey ${className}`}
      style={{ color: "var(--bs-body-color)", ...style }}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      <SntDiagnosticWarningIcon />
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const DangerIconTag = (props) => {
  const {
    tagName,
    title,
    children,
    size = "md",
    className = "",
    onClick,
    style,
  } = { ...props };

  return (
    <BadgeStyle
      size={size}
      bg="white"
      className={`border border-sensolus-grey ${className}`}
      style={{ color: "var(--bs-body-color)", ...style }}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      <SntDiagnosticFailureIcon />
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const AssetTag = (props) => {
  const {
    tagName,
    title,
    children,
    size = "md",
    className = "",
    onClick,
    style,
  } = { ...props };

  return (
    <DefaultStyle
      size={size}
      className={`border border-sensolus-grey ${className}`}
      style={{ ...style }}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      {children ? children : tagName}
    </DefaultStyle>
  );
};

export const PrimaryTag = (props) => {
  const { tagName, title, children, size = "md", className = "", onClick } = {
    ...props,
  };

  return (
    <BadgeStyle
      size={size}
      bg="sensolus"
      className={`border border-sensolus ${className}`}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const SecondaryTag = (props) => {
  const { tagName, title, children, size = "md", className = "", onClick } = {
    ...props,
  };

  return (
    <BadgeStyle
      size={size}
      bg="sensolus-secondary"
      className={`border border-sensolus-secondary ${className}`}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const GreyTag = (props) => {
  const { tagName, title, children, size = "md", className = "", onClick } = {
    ...props,
  };

  return (
    <BadgeStyle
      size={size}
      bg="sensolus-grey"
      className={`border border-sensolus-grey ${className}`}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const OrangeTag = (props) => {
  const { tagName, title, children, size = "md", className = "", onClick } = {
    ...props,
  };

  return (
    <BadgeStyle
      size={size}
      bg="sensolus-orange"
      className={`border border-sensolus-orange ${className}`}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const SuccessTag = (props) => {
  const { tagName, title, children, size = "md", className = "", onClick } = {
    ...props,
  };

  return (
    <BadgeStyle
      size={size}
      bg="success"
      className={`border border-success ${className}`}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const WarningTag = (props) => {
  const { tagName, title, children, size = "md", className = "", onClick } = {
    ...props,
  };

  return (
    <BadgeStyle
      size={size}
      bg="warning"
      className={`border border-warning ${className}`}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const DangerTag = (props) => {
  const { tagName, title, children, size = "md", className = "", onClick } = {
    ...props,
  };

  return (
    <BadgeStyle
      size={size}
      bg="danger"
      className={`border border-danger ${className}`}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const BlueTag = (props) => {
  const { tagName, title, children, size = "md", className = "", onClick } = {
    ...props,
  };

  return (
    <BadgeStyle
      size={size}
      bg="sensolus-blue"
      className={`border border-sensolus-blue me-2 ${className}`}
      title={title || tagName}
      onClick={onClick}
      role={onClick ? "button" : ""}
    >
      {children ? children : tagName}
    </BadgeStyle>
  );
};

export const CustomTag = ({
  className,
  tagName,
  title,
  children,
  bg,
  color,
  border,
  size = "md",
}) => {
  return (
    <BadgeStyle
      className={`me-2 ${className}`}
      size={size}
      title={title || tagName}
      ref={(el) => {
        if (el) {
          el.style.setProperty("background-color", bg, "important");
          el.style.setProperty("color", color, "important");
          el.style.setProperty("border", border, "important");
        }
      }}
    >
      <span>{children ? children : tagName}</span>
    </BadgeStyle>
  );
};
