import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SntRadio from "../../SntRadio/SntRadio";
import useOuterClick from "../useOutsideElement";
import GeneralFooterFilter from "../GeneralFooterFilter";
import ExcludeLabel from "../ExcludeLabel";
import { useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";
import { AdvanceFilter, FilterBody, FilterHeaderStyle } from "../FilterStyle";
import { VerticalLayout } from "../../ReactBootstrap/FormValidation";
import SntCloseSmallIcon from "../../Icons/SntCloseSmallIcon";
import SntArrowDownIcon from "../../Icons/SntArrowDownIcon";
import usePositionFilter from "../usePositionFilter";

// const [data, setData] = useState({
//     value: false,
//     notFilter: false
// })

const BooleanFilter = ({
  descriptor,
  data = {},
  onChange,
  disabled = false,
}) => {
  const [isShow, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const language = useSelector((state) => state.language);

  const [dataState, setDataState] = useState({
    selectedValue: "",
    notFilter: false,
  });

  let popupStyleRef = useRef({});
  const { popupRef, buttonRef, getPosition } = usePositionFilter();

  useEffect(() => {
    let { filterValue } = data;
    let _title = language.all_key,
      _dataState = {
        value: "",
        notFilter: false,
      };

    if (filterValue) {
      _dataState = filterValue;
    }

    if (_dataState.value) {
      _title = descriptor.filterTypeSettings.trueLabel || "Yes";
      _dataState.selectedValue = "trueLabel";
    } else if (_dataState.value === false) {
      _title = descriptor.filterTypeSettings.falseLabel || "No";
      _dataState.selectedValue = "falseLabel";
    } else if (_dataState.naOnly === true) {
      _title = descriptor.filterTypeSettings.naLabel || language.na_key;
      _dataState.selectedValue = "naLabel";
    }

    setDataState({
      selectedValue: _dataState.selectedValue,
      notFilter: data.notFilter && true,
    });
    setTitle(_title);
  }, [
    data,
    data.filterValue,
    descriptor.filterTypeSettings.falseLabel,
    descriptor.filterTypeSettings.trueLabel,
    descriptor.filterTypeSettings.naLabel,
    language.all_key,
    language.na_key,
  ]);

  const getData = useCallback(() => {
    let newData = JSON.parse(JSON.stringify(data));

    newData.filterValue = {
      value:
        dataState.selectedValue === "trueLabel"
          ? true
          : dataState.selectedValue === "falseLabel"
          ? false
          : null,
    };
    if (dataState.selectedValue === "naLabel")
      newData.filterValue.naOnly = true;
    else {
      delete newData.filterValue.naOnly;
    }
    newData.notFilter = dataState.notFilter;

    return newData;
  }, [data, dataState]);

  const isChanged = useCallback(() => {
    let oldDataFilter = data.filterValue || {};
    let newData = getData();
    let newDataFilter = newData.filterValue || {};

    if (
      oldDataFilter.value === newDataFilter.value &&
      oldDataFilter.naOnly === newDataFilter.naOnly &&
      (data.notFilter === newData.notFilter ||
        (data.notFilter === undefined && newData.notFilter === false) ||
        (data.notFilter === false && newData.notFilter === undefined))
    )
      return false;
    return true;
  }, [data, getData]);

  let refWrapper = useOuterClick((e) => {
    if (isShow) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    }
  });

  const onClickButton = () => {
    if (isShow) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    } else {
      popupStyleRef.current = getPosition();
      setShow(true);
    }
  };

  const onAppliedFilter = () => {
    setShow(false);
    if (isChanged()) onChange && onChange(getData());
  };

  const onClearFilter = () => {
    setShow(false);
    setDataState({ selectedValue: "", notFilter: false });
    onChange &&
      onChange({
        idx: descriptor._idx,
        data: null,
      });
  };

  const excludeLabel = useMemo(() => {
    return data.notFilter ? <ExcludeLabel /> : null;
  }, [data.notFilter]);

  return (
    <AdvanceFilter ref={refWrapper}>
      <Button
        ref={buttonRef}
        variant="sensolus-greylight"
        title={descriptor.description}
        disabled={disabled}
        onClick={onClickButton}
      >
        {dataState.selectedValue && (
          <SntCloseSmallIcon
            className="me-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClearFilter();
            }}
          />
        )}
        <span>{descriptor.label || descriptor.description}</span>:{" "}
        <span>{excludeLabel}</span>
        <FilterHeaderStyle>{title}</FilterHeaderStyle>
        <SntArrowDownIcon />
      </Button>

      <FilterBody
        ref={popupRef}
        style={{ display: isShow ? "block" : "none", ...popupStyleRef.current }}
      >
        <Container>
          <VerticalLayout className="mt-3">
            <SntRadio
              value="trueLabel"
              selectedValue={dataState.selectedValue}
              onChange={() =>
                setDataState({ ...dataState, selectedValue: "trueLabel" })
              }
              label={
                descriptor.filterTypeSettings.trueLabel
                  ? descriptor.filterTypeSettings.trueLabel
                  : "True"
              }
            />
            <SntRadio
              value="falseLabel"
              selectedValue={dataState.selectedValue}
              onChange={() => {
                setDataState({ ...dataState, selectedValue: "falseLabel" });
              }}
              label={
                descriptor.filterTypeSettings.falseLabel
                  ? descriptor.filterTypeSettings.falseLabel
                  : "False"
              }
            />

            {descriptor.filterTypeSettings.naLabel && (
              <SntRadio
                value="naLabel"
                selectedValue={dataState.selectedValue}
                onChange={() => {
                  setDataState({
                    ...dataState,
                    selectedValue: "naLabel",
                    naOnly: true,
                  });
                }}
                label={
                  descriptor.filterTypeSettings.naLabel
                    ? descriptor.filterTypeSettings.naLabel
                    : language.na_key
                }
              />
            )}
          </VerticalLayout>
        </Container>
        <GeneralFooterFilter
          onClearFilter={(e) => onClearFilter()}
          onAppliedFilter={() => onAppliedFilter()}
          isNotFilter={dataState.notFilter}
          onCheckNotFilter={(e) => {
            setDataState({
              ...dataState,
              notFilter: e,
            });
          }}
          disableCheckBox={!dataState.selectedValue}
        />
      </FilterBody>
    </AdvanceFilter>
  );
};

export default BooleanFilter;
